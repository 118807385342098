import { BillingDetails } from '@app/domain/BillingDetails'
import { BillingDetailsDTO } from '@services/DTO/BillingDetailsDTO'
import { DOCUMENT } from '@angular/common'
import { DTOAdapter } from '@services/DTOAdapter'
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, RendererFactory2 } from '@angular/core'
import { Observable, firstValueFrom, map } from 'rxjs'
import { environment } from '@environments/environment'

declare let Chargebee: any

@Injectable({
    providedIn: 'root',
})
export class BillingService {

    cbInstance: any
    private chargebeeScript = 'https://js.chargebee.com/v2/chargebee.js'

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter,
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) document: Document
    ) {
        const renderer = rendererFactory.createRenderer(null, null)
        const script = renderer.createElement('script')
        script.type = 'text/javascript'
        script.src = this.chargebeeScript
        renderer.appendChild(document.body, script)
        script.onload = () => {
            this.cbInstance = Chargebee.init({
                site: environment.chargebeeSite,
                publishableKey: environment.chargebeeKey,
            })
        }
    }

    openCheckoutTrialPortal(onSuccess: () => void) {
        this.cbInstance.logout()
        this.cbInstance.openCheckout({
            hostedPage: () => {
                return firstValueFrom(this.createTrialSubscriptionCheckout())
            },
            success: () => {
                onSuccess()
            },
        })
    }

    openManageSubscriptionPortal(onClose: () => void) {
        this.cbInstance.logout()
        this.cbInstance.setPortalSession(() => {
            return firstValueFrom(this.createPortalSession())
        })
        const portal = this.cbInstance.createChargebeePortal()
        portal.open({
            close: () => {
                onClose()
            },
        })
    }

    getBillingDetails(): Observable<BillingDetails> {
        const url = new URL('/organisation/billing', environment.apiBaseURL)
        return this.http.get<BillingDetailsDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptBillingDetailsDto(dto))
            )
    }

    private createTrialSubscriptionCheckout(): Observable<any> {
        const url = new URL('/organisation/billing/trial/subscription', environment.apiBaseURL)
        return this.http.post<any>(url.toString(), null)
    }

    private createPortalSession(): Observable<any> {
        const url = new URL('/organisation/billing/subscription/session', environment.apiBaseURL)
        return this.http.post<any>(url.toString(), null)
    }
}
